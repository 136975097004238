// fetchMediaMenifest.js
import fetch from '../resource/customFetch.js';
import parseM3u8 from './parseM3u8.js';
import parseMpd from './parseMpd.js';
import { getAssetManifest, MediaPreset } from '../resource/getMediaAsset.js';
import env from '../resource/env.js';

const parsers = {
  m3u8: parseM3u8,
  mpd: parseMpd,
};

/**
 * Fetch the media menifest
 * @param {String} { id } - the message id
 * @param {String} { assetId } - the message asset id
 * @param {String} { [ type ] } - the media type
 * @param {object} {[httpProxyHeaders = {}]} - http proxy headers for SSR.
 */
const fetchMediaMenifest = async ({
  id,
  assetId,
  type = 'm3u8',
  httpProxyHeaders = {},
}) => {
  const url = getAssetManifest({
    id,
    assetId,
    format: type,
    preset: MediaPreset.SD,
  });
  const isDecryptionRequired = env.FEATURE_RESOURCE_DECRYPTION === '1';
  const targetUrl = isDecryptionRequired
    ? url.href.replace(env.PUBLIC_URL_PREFIX, env.PUBLIC_ENCRYPTED_URL_PREFIX)
    : url.href;
  const response = await fetch(targetUrl, {
    method: 'GET',
    headers: {
      ...httpProxyHeaders,
    },
  });
  if (!response.ok) {
    throw new Error(`fetch_menifest_error [${response.status}]`);
  }

  const text = await response.text();
  const parsedData = await parsers[type]({ data: text, manifestUri: url.href });
  return { [type]: parsedData };
};

export default fetchMediaMenifest;
