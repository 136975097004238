// parseM3u8.js
'use strict';

const grepM3u8ContentType = m3u8MetaString => {
  /* 
    In m3u8 manifest, mainStreamUri is on one line after #EXT-X-STREAM-INF... 
    when m3u8 is Audio. the mainStreamURI will equal to the audioTrack URI.
    when m3u8 is Video. the mainStreamURI will not equal to the audioTrack URI.
  */
  const mainStreamURI = m3u8MetaString.match(
    /^(https?:\/\/.+)?(\/(v1|v2|drm)\/)?[\w\d-_]+\.m3u8(\?.*)?$/m
  )?.[0];
  const audioTrack = m3u8MetaString.match(/^#EXT-X-MEDIA.*/m)?.[0];
  if (!mainStreamURI) return null;
  if (audioTrack) {
    const isURIInAudioTrack = audioTrack.indexOf(mainStreamURI) !== -1;
    if (isURIInAudioTrack) {
      return 'audio';
    } else {
      return 'video';
    }
  } else {
    return 'image';
  }
};

const getMediaDuration = ({ segments }) => {
  return segments?.length
    ? segments.reduce((acc, { duration }) => acc + duration, 0)
    : null;
};

const parseM3u8 = async ({ data }) => {
  const mediaType = grepM3u8ContentType(data);
  const { Parser } = await import('../partial/m3u8-parser/Parser.js');
  const parser = new Parser();
  parser.push(data);
  parser.end();
  const manifest = parser.manifest;
  const duration = getMediaDuration(manifest);
  const m3u8ParseData = duration ? { ...manifest, duration } : manifest;
  return { ...m3u8ParseData, mediaType };
};

export default parseM3u8;
