// parseMpd.js
import { parse } from 'mpd-parser';

const grepMPDContentType = mpdXmlString => {
  const hasVideo = !!mpdXmlString.match('contentType="video"');
  const hasAudio = !!mpdXmlString.match('contentType="audio"');
  if (hasVideo && hasAudio) {
    return 'video';
  } else if (!hasVideo && hasAudio) {
    return 'audio';
  } else if (hasVideo && !hasAudio) {
    return 'image';
  } else {
    return null;
  }
};

const parseMpd = ({ data, manifestUri }) =>
  new Promise(resolve => {
    try {
      const mpdParseData = parse(data, { manifestUri });
      const mediaType = grepMPDContentType(data);
      resolve({
        ...mpdParseData,
        mediaType,
      });
    } catch (error) {
      resolve(null);
    }
  });

export default parseMpd;
